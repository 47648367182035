/* eslint-disable no-useless-escape */
import {useEffect} from 'react';
var ua = navigator.userAgent.toLowerCase()
export const isMobile=(/iphone/.test(ua)) || (/android/.test(ua)) // || (/ipad/.test(ua))
// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(search, replace) 
{
return this.split(search).join(replace);
}
export const arr_menu=[
{name:'Магазин наград',a:'store'},
{name:'Частые вопросы',a:'faq'},

];
export function open_menu(item) 
{
//window.location.hash='#'+item
  if (typeof item.link!=='undefined') window.open(item.link);
  else if (typeof document.getElementById(item.a)==='undefined' || document.getElementById(item.a)===null) window.location.href='/#'+item.a
  else
   {
window.history.replaceState(null, null, document.location.pathname+'#'+item.a);
window.scrollTo({top: document.getElementById(item.a).offsetTop-80,behavior: "smooth"})
   }
}
 //for animation scroll of div
const easing=(x)=>1-Math.pow(1 - x, 4)
export function animateDivScroll(target, left, duration,scrollTop=false)
{ 
let pos,start,aFrame
//const maxScroll=(scrollTop?(target.scrollHeight-target.clientHeight):(target.scrollWidth-target.clientWidth))
const init=(scrollTop?target.scrollTop:target.scrollLeft)
const amountToScroll=init-left;
window.cancelAnimationFrame(aFrame)
  function step(curtime)
   {
  if (start===undefined) start=curtime
const elapsed=curtime-start
const progress=elapsed/duration
const eProgress=easing(progress)
pos=init-amountToScroll*Math.min(eProgress,1)
  if (scrollTop) target.scrollTo({top:pos});
  else target.scrollTo({left:pos});
  /*  if (init!==maxScroll && target.scrollLeft===maxScroll)
   {
window.cancelAnimationFrame(aFrame)
return
   }*/
  if (elapsed<duration) aFrame =window.requestAnimationFrame(step);
    }
aFrame=window.requestAnimationFrame(step);
}

export function animateWindowScroll(left, duration, scrollTop=false) 
{
let pos, start, aFrame;
const maxScroll = scrollTop ? window.innerHeight - document.body.offsetTop : window.innerWidth - document.body.offsetLeft;
const init = scrollTop ? window.pageYOffset : window.pageXOffset;
const amountToScroll = init - left;
  function step(currentTime)
   {
if (start === undefined) start = currentTime;
const elapsed = currentTime - start;
const progress = elapsed / duration;
const easingProgress = easing(progress);
pos = init - amountToScroll * Math.min(easingProgress, 1);
    if (scrollTop) window.scrollTo({ top: pos });
    else  window.scrollTo({ left: pos });
    if (init !== maxScroll && window.pageXOffset === maxScroll /*|| window.pageYOffset === maxScroll*/) 
     {
      window.cancelAnimationFrame(aFrame);
      return;
     }
    if (elapsed < duration) aFrame = window.requestAnimationFrame(step);
   }
aFrame = window.requestAnimationFrame(step);
}



export function useEvent(event,handler,passive=false)
{
useEffect(()=>
  {
window.addEventListener(event, handler, passive)
    return function cleanup()
    {
window.removeEventListener(event, handler)
    }
  })
}
export function elementVisible(el)
{
el=document.getElementById(el)
const rect = el.getBoundingClientRect();
return (rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth));
}
//вернуть предыдущее, или следующее число для перехода
export function returnNext(prev,id,count)
{ 
return (prev?(id+count-1)%count:(id+1)%count)
}
export const encodeDataToURL=(data)=> 
{
return Object
.keys(data)
.map(value => `${value}=${encodeURIComponent(data[value])}`)
.join('&');
 }
 
 export function stripTags(s,allow) //only some tags is accepted
{
  if (typeof s==='undefined' || s===null) return s
  if (allow===null || typeof alllow==='undefined')  allow=['p','span','strong','em','h1','h2','h3','ol','li','ul','blockquote','a','img','video','div','br'];
  //'p','span','strong','em','h1','h2','h3','ol','li','ul','blockquote','a','img','video','div'
return s.replace( new RegExp(`\\s*<(?!\/?\\b(${allow.join('|')})\\b)[^>]+>\\s*`, 'gim' ),'');
}

export function sanitTags(s) //sanitize all tags
{
  if (typeof s==='undefined' || s===null) return s
return s.replace(/<\/?[^>]+(>|$)/gim, "");
}

 export const returnImgPath=(theme,img)=>
 {
let path='/i/black/'+img
  if (theme==='white_theme') path='/i/white/'+img
return path
 }
 export function goApp()
{
  if (/android/.test( navigator.userAgent.toLowerCase())) window.open("https://redirect.appmetrica.yandex.com/serve/316668474296386215?click_id=%7BLOGID%7D&search_term=%7Bkeyword%7D&device_type=%7Bdevice_type%7D&region_name=%7Bregion_name%7D&source_type=%7Bsource_type%7D&phrase_id=%7Bphrase_id%7D&source=%7Bsource%7D&position_type=%7Bposition_type%7D&campaign_id=%7Bcampaign_id%7D")
  else window.open("https://redirect.appmetrica.yandex.com/serve/965186825066976482?click_id=%7BLOGID%7D&search_term=%7Bkeyword%7D&device_type=%7Bdevice_type%7D&region_name=%7Bregion_name%7D&source_type=%7Bsource_type%7D&phrase_id=%7Bphrase_id%7D&source=%7Bsource%7D&position_type=%7Bposition_type%7D&campaign_id=%7Bcampaign_id%7D")
}
export const delay=(x)=> 
{
return new Promise((resolve, reject)=>
  {
setTimeout(() => {resolve('')}, x)
  });
}
export const IconQuestion=({cls,click,title})=>
{
return(
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={cls} onClick={click} title={title}>
<title>{title}</title>
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="url(#paint0_linear_2355_6051)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.7014 6.80253C10.3629 6.29984 11.2539 6 12.0431 6C13.5281 6 14.6183 6.58572 15.2411 7.55674C15.8438 8.49634 15.9405 9.69034 15.6942 10.8099C15.4102 12.1008 14.3574 12.8927 13.5261 13.3377C13.0957 13.5682 12.6842 13.7277 12.3817 13.8297C12.3778 13.831 12.374 13.8323 12.3701 13.8335L12.3594 14.6751C12.3547 15.0433 12.0525 15.338 11.6843 15.3333C11.3162 15.3286 11.0215 15.0263 11.0262 14.6582L11.0432 13.3249C11.0471 13.0179 11.2601 12.7533 11.559 12.6839C11.559 12.6839 11.559 12.6839 11.559 12.6839C11.559 12.6839 11.559 12.6839 11.559 12.6839L11.5592 12.6839L11.5594 12.6838C11.5595 12.6838 11.5595 12.6838 11.5595 12.6838C11.5596 12.6838 11.5597 12.6838 11.5597 12.6838C11.5599 12.6837 11.56 12.6837 11.5601 12.6837L11.5619 12.6832L11.5794 12.6789C11.596 12.6747 11.6223 12.6679 11.6571 12.6583C11.7268 12.639 11.8297 12.6087 11.9558 12.5662C12.2095 12.4806 12.5489 12.3485 12.8968 12.1623C13.6221 11.774 14.236 11.2325 14.392 10.5234C14.5857 9.64299 14.4782 8.83699 14.1188 8.27659C13.7795 7.74761 13.1497 7.33333 12.0431 7.33333C11.5522 7.33333 10.9432 7.53349 10.5081 7.86413C10.0736 8.19434 9.94672 8.53233 10.0232 8.83831C10.1125 9.19551 9.89534 9.55746 9.53814 9.64676C9.18095 9.73606 8.81899 9.51889 8.72969 9.16169C8.47285 8.13434 9.0393 7.30566 9.7014 6.80253ZM11.6928 16.5582C12.061 16.5582 12.3595 16.8567 12.3595 17.2249V17.3333C12.3595 17.7015 12.061 18 11.6928 18C11.3246 18 11.0262 17.7015 11.0262 17.3333V17.2249C11.0262 16.8567 11.3246 16.5582 11.6928 16.5582Z" fill="var(--accent-color)"/>
<defs>
<linearGradient id="paint0_linear_2355_6051" x1="27.217" y1="-10.0541" x2="12.4457" y2="37.1887" gradientUnits="userSpaceOnUse">
<stop stopColor="#ABABAB"/>
<stop offset="1" stopColor="#ABABAB" stopOpacity="0.05"/>
</linearGradient>
</defs>
</svg>
)
}